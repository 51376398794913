import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAuth } from '../auth';
import Layout from '../components/layout';
import { Navigation } from '../components/navigation';
import { SupportContact } from '../components/support-contact';

export const schema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email('Bitte gib eine gültige E-Mail Adresse an!'),
  password: yup
    .string()
    .min(5, 'Das Passwort sollte mindestens 5 Zeichen enthalten.')
    .required('Dies ist ein Pflichtfeld.'),
  acceptPrivacy: yup
    .boolean()
    .required('Dies ist ein Pflichtfeld.')
    .oneOf(
      [true],
      'Bitte lies die Hinweise zum Datenschutz und akzeptiere diese!'
    ),
  acceptTermsAndConditions: yup
    .boolean()
    .required()
    .oneOf([true], 'Bitte lies unsere AGB und akzeptiere diese!'),
  repeatPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), undefined],
      'Die Passwörter müssen übereinstimmen.'
    ),
  firstName: yup.string().required('Dies ist ein Pflichtfeld.'),
  lastName: yup.string().required('Dies ist ein Pflichtfeld.')
});

export default () => {
  const { register, handleSubmit, watch, errors, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const { login, user, register: fakeOSRegister } = useAuth();
  const [error, setError] = useState<string>(undefined);
  const [success, setSuccess] = useState(false);
  const onSubmit = useCallback(async data => {
    try {
      await fakeOSRegister(getValues());
      setSuccess(true);
    } catch (e) {
      setError(
        'Hier scheint etwas nicht zu stimmen. Wahrscheinlich ist diese E-Mail im System bereits vergeben.'
      );
      console.log(typeof e);
    }
  }, []);
  return (
    <Layout>
      <Navigation fixed />
      <div className="section">
        <div className="container is-max-desktop">
          <h2 className="subtitle">fakeOS</h2>
          <h3 className="title">Registrieren</h3>
          <p>
            Um deinen persönlichen Zugang zur kostenlosen Testversion zu
            bekommen, nimm bitte telefonisch oder per E-Mail mit unserem Support
            Kontakt auf!
          </p>
          <SupportContact />
        </div>
      </div>
    </Layout>
  );
  /* return (
    <Layout>
      <Navigation fixed />
      <div className="section">
        <div className="container is-max-desktop">
          <h2 className="subtitle">fakeOS</h2>
          <h3 className="title">Registrieren</h3>
          {success && (
            <>
              <div className="notification is-success is-light">
                <h2 className="title">Vielen Dank!</h2>
                <p>
                  Bitte überprüfe dein E-Mail Postfach, um die Registrierung
                  abzuschließen!
                </p>
              </div>
            </>
          )}
          {!success && (
            <>
              <p>
                Um fakeOS als <strong>kostenlose Testversion</strong> oder in
                der <strong>Vollversion</strong> zu nutzen, benötigst du einen
                Account.
              </p>
              <p>
                Mache in ein paar Sekunden einige Angaben, erhalte eine E-Mail
                und bestätige deine Registrierung.
              </p>
              <p>
                <strong>Und dann kann es direkt losgehen!</strong>
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  type="email"
                  placeholder="E-Mail"
                  label="E-Mail"
                  name="email"
                  innerRef={register}
                  error={errors.email?.message}
                />
                <InputField
                  type="password"
                  label="Passwort"
                  placeholder="Passwort"
                  name="password"
                  innerRef={register}
                  error={errors.password?.message}
                />
                <InputField
                  type="password"
                  label="Passwort wiederholen"
                  placeholder="Passwort wiederholen"
                  name="repeatPassword"
                  innerRef={register}
                  error={errors.repeatPassword?.message}
                />

                <Selectbox
                  name="gender"
                  label="Anrede"
                  choices={[
                    { label: 'Frau', value: '0' },
                    { label: 'Herr', value: '1' },
                    { label: 'Divers', value: '2' }
                  ]}
                />

                <InputField
                  placeholder="Vorname"
                  label="Vorname"
                  name="firstName"
                  innerRef={register}
                  error={errors.firstName?.message}
                />

                <InputField
                  placeholder="Nachname"
                  label="Nachname"
                  name="lastName"
                  innerRef={register}
                  error={errors.lastName?.message}
                />

                <InputField
                  placeholder="Geburtsdatum"
                  label="Geburtsdatum"
                  name="dateOfBirth"
                  innerRef={register}
                  type="date"
                  error={errors.dateOfBirth?.message}
                />

                <InputField
                  placeholder="Firma"
                  label="Firma"
                  name="company"
                  innerRef={register}
                  error={errors.company?.message}
                />

                <InputField
                  placeholder="Straße und Hausnummer"
                  label="Straße und Hausnummer"
                  name="streetAndNumber"
                  innerRef={register}
                  error={errors.streetAndNumber?.message}
                />

                <InputField
                  placeholder="Postleitzahl"
                  label="Postleitzahl"
                  name="postalCode"
                  innerRef={register}
                  error={errors.postalCode?.message}
                />

                <InputField
                  placeholder="Stadt"
                  label="Stadt"
                  name="city"
                  innerRef={register}
                  error={errors.city?.message}
                />

                <InputField
                  placeholder="Land"
                  label="Land"
                  name="country"
                  innerRef={register}
                  error={errors.country?.message}
                />

                <InputField
                  placeholder="Telefon"
                  label="Telefon"
                  name="phone"
                  innerRef={register}
                  error={errors.phone?.message}
                />
                <div className="box has-background-primary-light">
                  <h3 className="subtitle">Demo-Account</h3>
                  <p>
                    Wenn du dich mit fakeOS ein wenig vertraut machen möchtest,
                    kannst du dies zun. Wir erstellen für dich eine
                    „Demo-Produktion“, mit der du alle Features ausprobieren
                    kannst. Du kannst jederzeit zur Vollversion wechseln.
                  </p>
                  <p>Aktiviere dafür die folgende Checkbox!</p>

                  <Checkbox
                    innerRef={register}
                    name="orderDemoAccount"
                    label={<strong>Ja, Demo-Produktion erstellen!</strong>}
                  />
                </div>

                <Checkbox
                  innerRef={register}
                  name="acceptTermsAndConditions"
                  error={errors.acceptTermsAndConditions?.message}
                  label={
                    <>
                      Ich habe die{' '}
                      <a target="_blank" href="/agb">
                        AGB
                      </a>{' '}
                      gelesen und akzeptiere diese.
                    </>
                  }
                />

                <Checkbox
                  innerRef={register}
                  name="acceptPrivacy"
                  error={errors.acceptPrivacy?.message}
                  label={
                    <>
                      Ich habe die{' '}
                      <a target="_blank" href="/datenschutz">
                        Hinweise zum Datenschutz
                      </a>{' '}
                      gelesen und akzeptiere diese.
                    </>
                  }
                />

                <div className="control">
                  <button type="submit" className="button is-link">
                    Registrieren
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  ); */
};
